/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Colors} from '../../../src/components/Colors';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "customizing-colors",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customizing-colors",
    "aria-label": "customizing colors permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customizing Colors"), "\n", React.createElement(_components.p, null, "Customizing the default color palette for your project."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "palette-reference",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#palette-reference",
    "aria-label": "palette reference permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Palette Reference"), "\n", React.createElement(_components.p, null, "xstyled includes ", React.createElement(_components.a, {
    href: "https://tailwindcss.com/docs/customizing-colors"
  }, "Tailwind colors"), " as a default palette that is a great starting point if you don't have your own specific branding in mind."), "\n", "\n", React.createElement(Colors, {
    my: 8
  }), "\n", React.createElement(_components.h2, {
    id: "alpha-variants",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#alpha-variants",
    "aria-label": "alpha variants permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Alpha Variants"), "\n", React.createElement(_components.p, null, "All colors includes 10 degrees of alpha variants, for example you can use ", React.createElement(_components.code, null, "red-500-a50"), " to display a ", React.createElement(_components.code, null, "red-500"), " color with an opacity of ", React.createElement(_components.code, null, "0.5"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "<x.p color=\"red-500-a50\" />\n")), "\n", React.createElement(_components.h2, {
    id: "add-colors",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#add-colors",
    "aria-label": "add colors permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Add colors"), "\n", React.createElement(_components.p, null, "Even if the included palette is large, feel free to add your own colors."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { defaultTheme } from '@xstyled/...'\n\nexport const theme = {\n  colors: {\n    ...defaultTheme.colors,\n    'blue-light': '#85d7ff',\n  },\n}\n")), "\n", React.createElement(_components.p, null, "Alpha variants are not automatically generated. To get it, you have to use ", React.createElement(_components.code, null, "generateHexAlphaVariants"), " utility."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { defaultTheme, generateHexAlphaVariants } from '@xstyled/...'\n\nexport const theme = {\n  colors: {\n    ...defaultTheme.colors,\n    // Generate 'blue-light-a10', 'blue-light-a20', ...\n    ...generateHexAlphaVariants({\n      'blue-light': '#85d7ff',\n    }),\n  },\n}\n")), "\n", React.createElement(_components.h2, {
    id: "alias-colors",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#alias-colors",
    "aria-label": "alias colors permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Alias Colors"), "\n", React.createElement(_components.p, null, "It is always a good choice to alias colors, for example define a ", React.createElement(_components.code, null, "primary"), " that refers to ", React.createElement(_components.code, null, "emerald"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { defaultTheme, aliasColor } from '@xstyled/...'\n\nexport const theme = {\n  colors: {\n    ...defaultTheme.colors,\n    // Using `primary-500` is equivalent to `emerald-500`\n    ...aliasColor('primary', 'emerald'),\n  },\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
